<script setup lang="ts">
const { apiClient } = useShopwareContext();
const { renderButton } = useAmazonPay();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const BUTTON_CONTAINER_ID = "amazon-pay-express-button";

onMounted(async () => {
  const button = await renderButton("#" + BUTTON_CONTAINER_ID, "Cart");
  button.onClick(async () => {
    const { data } = await apiClient.invoke(
      "amazonPayButton post /amazon-pay-checkout-button",
      {
        body: {
          reviewReturnUrl: formatUrl("/checkout"),
        },
      },
    );

    button.initCheckout({
      estimatedOrderAmount: data.estimatedOrderAmount,
      createCheckoutSessionConfig: {
        payloadJSON: data.payload,
        signature: data.signature,
        algorithm: "AMZN-PAY-RSASSA-PSS",
      },
    });
  });

  const style = document.createElement("style");
  style.textContent = `
  .amazonpay-button-container-rows {
    grid-template-rows: 40px 4px 10px; }
  `;
  document.querySelector("#" + BUTTON_CONTAINER_ID)?.shadowRoot?.append(style);
});

function formatUrl(path: string | RouteObject) {
  const link = formatLink(path);
  const url = typeof link == "string" ? link : link.path;
  return new URL(url, window.location.href).href;
}
</script>

<template>
  <div :id="BUTTON_CONTAINER_ID">
    <div
      class="h-10 b-solid b-[#9c7e31] b-t-[#a88734] b-b-[#846a29] rd-[3px] b bg-gradient-linear shape-bottom from-[#f7dfa5] to-[#f0c14b]"
    ></div>
  </div>
</template>
